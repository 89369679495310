exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cookie-index-jsx": () => import("./../../../src/pages/cookie/index.jsx" /* webpackChunkName: "component---src-pages-cookie-index-jsx" */),
  "component---src-pages-handelsbetingelser-index-jsx": () => import("./../../../src/pages/handelsbetingelser/index.jsx" /* webpackChunkName: "component---src-pages-handelsbetingelser-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-losninger-index-jsx": () => import("./../../../src/pages/losninger/index.jsx" /* webpackChunkName: "component---src-pages-losninger-index-jsx" */),
  "component---src-pages-om-os-index-jsx": () => import("./../../../src/pages/om-os/index.jsx" /* webpackChunkName: "component---src-pages-om-os-index-jsx" */),
  "component---src-pages-persondatapolitik-index-jsx": () => import("./../../../src/pages/persondatapolitik/index.jsx" /* webpackChunkName: "component---src-pages-persondatapolitik-index-jsx" */)
}

